'use client';
import { useRouter } from 'next/navigation';
import React from 'react';
import { LoginUserForm } from './LoginUserForm';
import { authenticationInverseActionContent } from './GatedContentRegister';
export const Login = () => {
  const router = useRouter();

  return (
    <LoginUserForm
      inverseAction={{
        type: 'href',
        path: '/register',
        ...authenticationInverseActionContent.register,
      }}
      onDone={() => router.push('/')}
    />
  );
};
